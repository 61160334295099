import request from './request';
import {
  IChemicalRecord,
  IChemicalMeasurements,
} from '../model/chemicalRecord';

export const getChemicalRecord = ({id, chemical}: IChemicalRecord) =>
  request.request<IChemicalMeasurements>({
    url: `/sensors/${id}/records/${chemical}/`,
    method: 'GET',
  });

export const getChemicalRecordByInterval = ({
  id,
  chemical,
  interval,
}: IChemicalRecord) =>
  request.request<IChemicalMeasurements>({
    url: `/sensors/${id}/records/${chemical}/${interval}/`,
    method: 'GET',
  });

export const getChemicalRecordAllSensors = ({chemical}: IChemicalRecord) =>
  request.request<IChemicalMeasurements>({
    url: `/sensors/${chemical}/`,
    method: 'GET',
  });
