import {IcompareChart} from 'model/compare';
import {Iband} from 'model/compare';

export const getAxisFormat = (interval: string) => {
  switch (interval) {
    case 'Last 1 hour':
      return {
        format: '%I:%M %p',
        tickRotation: -50,
      };
    case 'Last 24 hours':
      return {
        format: '%I %p',
        tickRotation: -50,
      };

    case 'Last 7 days':
      return {
        format: '%a',
        tickRotation: -50,
      };

    case 'Last 30 days':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };

    case 'Last 3 month':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };

    case 'Last 12 month':
      return {
        format: '%b',
        tickRotation: -50,
      };
  }
};

export const calculateBandLevels = (series: IcompareChart[]) => {
  const conductivityData: IcompareChart | undefined = series.find(
      (data: IcompareChart) => data.id === 'conductivity',
  );
  const waterTemperatureData: IcompareChart | undefined = series.find(
      (data: IcompareChart) => data.id === 'water temperature',
  );
  const turbidityData: IcompareChart | undefined = series.find(
      (data: IcompareChart) => data.id === 'turbidity',
  );
  const phData: IcompareChart | undefined = series.find(
      (data: IcompareChart) => data.id === 'ph',
  );
  const chlorineData: IcompareChart | undefined = series.find(
      (data: IcompareChart) => data.id === 'chlorine',
  );

  const BandLevels: Iband = {
    max: 120,
    min: 0.2,
  };

  if (conductivityData) {
    BandLevels.max = 800;
  } else if (waterTemperatureData) {
    BandLevels.max = 80;
  } else if (turbidityData) {
    BandLevels.max = 10;
  } else if (phData) {
    BandLevels.max = 8.5;
  } else {
    BandLevels.max = 2;
  }

  if (chlorineData) {
    BandLevels.min = 0.2;
  } else if (phData) {
    BandLevels.min = 6.5;
  } else if (turbidityData) {
    BandLevels.min = 0;
  } else if (waterTemperatureData) {
    BandLevels.min = 50;
  } else {
    BandLevels.min = 200;
  }
  return BandLevels;
};
