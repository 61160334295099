import {area, curveMonotoneX} from 'd3-shape';
import {calculateBandLevels} from './utils/compare';

const SafeBand = ({series, xScale, yScale}: any) => {
  const bandLevels = calculateBandLevels(series);
  const areaGenerator =
    area()
        ?.x((d: any) => xScale(d?.data?.x))
        ?.y0(() => {
          return yScale(bandLevels.min);
        })
        ?.y1(() => {
          return yScale(bandLevels.max);
        })
        ?.curve(curveMonotoneX) ?? undefined;

  return (
    <>
      <path
        d={`${areaGenerator(series[0].data as any)}`}
        fill="#9D9D9D2B"
        fillOpacity={0.6}
        stroke="#9D9D9D2B"
        strokeWidth={1}
      />
    </>
  );
};

export default SafeBand;
