import React from 'react';
import RoundedImage from './elements/roundedImage';
import Typography from './elements/typography';

interface Iprops {
  firstName: string;
  lastName: string;
  image: string;
}

const Account = ({firstName, lastName, image}: Iprops) => {
  return (
    <div className={'grid gap-2 grid-flow-col items-center'} onClick={() => {}}>
      <Typography
        style={{
          marginRight: 22,
          font: 'normal normal 600 1.5rem/19px Noto Sans',
          letterSpacing: '0px',
          color: '#707070',
          opacity: 1,
        }}
        size={'md'}
        className={'text-textGrey capitalize font-bold hidden lg:block'}
      >
        {firstName} {lastName}
      </Typography>
      <RoundedImage
        src={
          image ||
          'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png'
        }
        alt={''}
      />
    </div>
  );
};

export default Account;
