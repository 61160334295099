import React, {useState, ReactNode} from 'react';
import {defaultUserDetail} from 'context/userContext';
import {IUserDetails} from 'model/user';
import {Iprofile} from 'model/profile';
import {UserContext} from './userContext';
import {storage} from '../util';

/** Get user from storage */
const user = storage.getItem('user');

/** Build user details from user information in the storage */
const getUser = (): IUserDetails => ({
  profile: JSON.parse(user as string),
  setProfile: () => {},
});

/** Create default state if a user is loggedIn */
const defaultState: IUserDetails = user ? getUser() : defaultUserDetail;

const UserProvider = ({children}: { children: ReactNode }) => {
  const [state, setState] = useState<IUserDetails>(defaultState);

  const saveUser = (user: Iprofile) => {
    setState({...state, profile: user});
  };

  return (
    <UserContext.Provider value={{...state, setProfile: saveUser}}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
