import axios from 'axios';
import {storage} from '../util';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  const token = storage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        storage.clear();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    },
);

export default instance;
