import React, {useEffect, useState, useCallback} from 'react';
import Card from '../../../components/elements/card';
import Typography from '../../../components/elements/typography';
import Table from '../../../components/table';
import {
  representTableData,
  convertFromCelciusToFarenheight,
  getStatus,
} from '../../../util';
import Dropdown from 'components/dropdown';
import {chemicals} from '../../../constants';
import {getChemicalRecord} from 'api/chemicalRecord';
import {IWaterStation} from 'model/waterStation';
import {
  IchemicalList,
  IChemicalRecord,
  IMeasurementRecord,
  IChemicalMeasurements,
} from 'model/chemicalRecord';
import {Imeasurement} from 'model/measurement';
import moment from 'moment';

const getMeasurement = (data: Imeasurement[]) =>
  representTableData(data, (res) => ({
    measurement: (
      <Typography>
        {moment(res.measurement).format('DD-MM-YYYY, HH:mm a')}
      </Typography>
    ),
    value: <Typography>{res.value}</Typography>,
    status: (
      <Typography
        style={{
          color:
            res.status.includes('Low') || res.status.includes('High') ?
              '#F4A335' :
              '#74A343',
          fontSize: '1rem',
        }}
      >
        {res.status}
      </Typography>
    ),
  }));

const Measurements = ({waterStation}: { waterStation: IWaterStation }) => {
  const [measurmentData, setMeasurmentData] = useState<Imeasurement[]>([]);
  const [selected, makeSelection] = useState<number>(0);

  const handleSelect = (value: number) => {
    makeSelection(value);
  };

  const fetchRecords = useCallback(async () => {
    const selectedSensor = waterStation.sensors[waterStation.activeSensorIndex];
    try {
      const selectedChemical: IchemicalList = chemicals[selected];
      const payload: IChemicalRecord = {
        id: selectedSensor.id,
        chemical: selectedChemical,
      };
      const {data}: { data: IChemicalMeasurements } = await getChemicalRecord(
          payload,
      );

      const measurements: Imeasurement[] = data.results.records.map(
          (record: IMeasurementRecord) => {
            if (
              selectedChemical === 'water temperature' &&
            record.unit === '°C'
            ) {
              const temperature = convertFromCelciusToFarenheight(record.value);
              return {
                measurement: record.time,
                value: `${temperature} °F`,
                status: getStatus(selectedChemical, Number(temperature)),
              };
            } else {
              return {
                measurement: record.time,
                value: `${record.value.toFixed(2)}${
                record.unit === 'ppm' ? 'mg/l' : record.unit
                }`,
                status: getStatus(selectedChemical, record.value),
              };
            }
          },
      );
      setMeasurmentData(measurements);
    } catch (error) {}
  }, [waterStation.sensors, selected, waterStation.activeSensorIndex]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
    }
  }, [fetchRecords, waterStation.sensors.length]);

  return (
    <Card maxHeight={420} className="small-card-first">
      <div className="card-header">
        <div className="card-header-section1 black-drop-down">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            measurements
          </Typography>
          <Dropdown
            list={chemicals}
            onChange={handleSelect}
            value={chemicals[selected]}
          />
        </div>
      </div>
      <div className="v-card-table my-table">
        <Table
          heading={['measurement', 'value', 'status']}
          columns={getMeasurement(measurmentData)}
        />
      </div>
    </Card>
  );
};

export default Measurements;
