import React from 'react';
import Login from './login';
import Registration from './registration';
import Card from '../../components/elements/card';
import logoFull from '../../assets/images/logo-full.png';
import './base.style.css';
import Typography from '../../components/elements/typography';
import {IUpdateProfile} from 'model/user';
import {IUpdateSensors} from 'model/waterStation';

interface IProps extends IUpdateProfile, IUpdateSensors {}

const Auth = (props: IProps) => {
  return (
    <div className={'v-auth'}>
      <Card className={'v-login-card'}>
        <div className={'flex items-center justify-center mt-7 mb-11'}>
          <img src={logoFull} alt="" style={{maxWidth: 230}} />
        </div>
        <Login setProfile={props.setProfile} />
        <div className={'v-auth-separator'}>
          <Typography className={'px-5 text-textGrey font-bold'}>or</Typography>
        </div>
        <Registration />
      </Card>
    </div>
  );
};

export default Auth;
