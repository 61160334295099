import React from 'react';
import Compare from '../components/compare';
import Overview from '../components/overview';
import SensorMap from '../components/sensorMap';
// import Insights from './insights';
import Sensors from '../components/sensors';
// import WaterQuality from './waterQuality';
// import Activity from './activity';
import SensorLevels from '../components/sensorLevels';
import LocalTemp from '../components/localTemp';
import Measurements from '../components/measurements';
import './base.style.css';
import {IWaterStation} from 'model/waterStation';

interface Iprops {
  waterStation: IWaterStation;
}
const Home = ({waterStation}: Iprops) => {
  return (
    <div>
      <div className="v-card-layout">
        <Compare waterStation={waterStation} />
        <Overview waterStation={waterStation} />
        <SensorMap waterStation={waterStation} />
        <Measurements waterStation={waterStation} />
        <Sensors
          sensors={waterStation.sensors}
          setActiveSensor={waterStation.setActiveSensor}
        />
        <SensorLevels waterStation={waterStation} />
        <LocalTemp waterStation={waterStation} />
      </div>
    </div>
  );
};

export default Home;
