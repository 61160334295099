import React, {useState} from 'react';
import Input from '../../components/elements/inputs';
import Button from '../../components/elements/button';
import {IRegistration} from 'model/registration';

const defaultState: IRegistration = {
  error: '',
  email: '',
  loading: false,
  emailError: false,
};

const RegistrationForm = () => {
  const [state] = useState(defaultState);
  const handleSubmit = () => {};
  const handleChange = () => {};
  return (
    <form className={'grid gap-5 mt-4'} onSubmit={handleSubmit}>
      <Input
        name="email"
        placeholder="Enter Email Address"
        value={state.email}
        onChange={handleChange}
        error={state.emailError}
      />
      <div className={'flex justify-end'}>
        <Button text={'sign up'} loading={state.loading} />
      </div>
    </form>
  );
};

export default RegistrationForm;
