import {PropsWithChildren} from 'react';
import {PointTooltipProps} from '@nivo/line';
import {BarTooltipProps, BarDatum} from '@nivo/bar';
import {IcompareChart} from 'model/compare';
import moment from 'moment';
import './base.style.css';

const ToolTip = ({
  point,
  data,
  bar,
}: {
  point?: PropsWithChildren<PointTooltipProps>;
  data: IcompareChart[];
  bar?: PropsWithChildren<BarTooltipProps<BarDatum>>;
}) => {
  if (point) {
    const p = point?.point;
    const unit: string = data.find((d) => d.id === p?.serieId)?.unit ?? '';
    const chemicaldata: string = p?.data?.yFormatted.toString() ?? '';
    const formattedUnit: string = unit === '°C' ? '°F' : unit;
    const formattedTime: string = moment(p?.data?.xFormatted).format(
        'YYYY-MM-DD HH:mm',
    );
    return (
      <div className="tool-tip-container" style={{zIndex: 10000000000}}>
        <div className="tool-tip-time">{formattedTime}</div>
        <div className="tool-tip-value">
          {chemicaldata} {formattedUnit}
        </div>
      </div>
    );
  }
  if (bar) {
    console.log({bar});
  }
  return <div />;
};

export default ToolTip;
