import React from 'react';
import {IUserDetails} from '../model/user';

export const defaultUserDetail: IUserDetails = {
  profile: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    role: 0,
    profile: {
      id: 0,
      water_utility: {
        id: 0,
        location: {
          street_address_1: '',
          street_address_2: null,
          city: '',
          state: '',
          zip_code: '',
          country: '',
          longitude: 0,
          latitude: 0,
          formatted_address: '',
        },
        name: '',
        url: null,
        water_system_id: '',
        population: 0,
        num_meters: 0,
        primary_water_source_type: '',
        water_system_type: '',
        is_pws_activity_active: false,
        principal_county_served: null,
        principal_city_served: null,
        system_status: '',
        activity_date: '',
      },
      phone_number: '',
      subscribe_desc: '',
      is_approved: false,
    },
  },
  setProfile: () => {},
};

export const UserContext = React.createContext(defaultUserDetail);
