import React, {useState, useEffect, useCallback} from 'react';
import Typography from '../../../components/elements/typography';
import Card from '../../../components/elements/card';
import './base.style.css';
import {IWaterStation} from 'model/waterStation';
import BarChart from 'components/barchart';
import Dropdown from 'components/dropdown';
import {chemicals} from '../../../constants';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {IkeyValues} from 'model/home';
import {convertFromCelciusToFarenheight} from '../../../util';
import {compareOptions} from '../../../constants';

interface IBarchartData {
  location: string;
  value: number;
  color?: string;
  issue?: boolean;
  unit?: string;
  label?: string;
}
const SensorLevels = ({waterStation}: { waterStation: IWaterStation }) => {
  const [selected, makeSelection] = useState<number>(0);
  const [barChartData, setBarChartData] = useState<IBarchartData[]>([]);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [selectedInterval, setSelectedInterval] = useState<number>(0);

  const fetchChemicalRecord = useCallback(async () => {
    if (waterStation.sensors.length > 0) {
      try {
        const requests = waterStation.sensors.map((sensor) => {
          const interval: IkeyValues = chemicalInterval[
              selectedInterval
          ] as IkeyValues;
          return getChemicalRecordByInterval({
            id: sensor.id,
            chemical: chemicals[selected],
            interval: compareOptions[interval],
          });
        });
        const resp = await Promise.all(requests);
        const data: IBarchartData[] = [];
        waterStation.sensors.forEach((sensor, key) => {
          const sensorData = resp[key].data;
          if (sensorData.count > 0) {
            const value = sensorData.results.records[0]?.value;
            const unit = sensorData.results.records[0]?.unit;
            const formattedUnit: string =
              unit === 'ppm' ? 'mg/l' : unit === '°C' ? '°F' : unit;
            const chemical = chemicals[selected];
            const result = {
              location:
                `${sensor.location.formatted_address}` +
                ` ${sensor.location.city}`,
              value:
                chemical === 'water temperature' ?
                  Number(convertFromCelciusToFarenheight(value)) :
                  Math.round(value * 1000) / 1000,
              unit: formattedUnit,
              chemical,
              label:
                sensor?.location?.street_address_1 ||
                sensor.location.formatted_address ||
                '',
            };
            data.push(result);
          }
        });
        setBarChartData(data);
      } catch (error) {
        console.log({error});
      }
    } // eslint-disable-next-line
  }, [selected, selectedInterval, waterStation.sensors.length]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchChemicalRecord();
    }
  }, [fetchChemicalRecord, waterStation.sensors.length]);

  return (
    <Card
      maxHeight={420}
      className="v-card lg:col-start-1 lg:col-end-3 large-card"
    >
      <div className="card-header">
        <div className="card-header-section1">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            sensor levels
          </Typography>
          <div
            className="flex items-center black-drop-down"
            style={{maxWidth: 460}}
          >
            <Dropdown
              style={{width: 200, zIndex: 100000}}
              list={chemicals}
              onChange={makeSelection}
              value={chemicals[selected]}
            />

            <Dropdown
              style={{marginLeft: 20, width: 200, zIndex: 100000}}
              list={chemicalInterval}
              value={chemicalInterval[selectedInterval]}
              onChange={setSelectedInterval}
            />
          </div>
        </div>
      </div>
      <div
        style={{width: '100%', height: '342px', minHeight: 0, minWidth: 0}}
        className="bar-chart"
      >
        <BarChart data={barChartData} chemical={chemicals[selected]} />
      </div>
    </Card>
  );
};

export default SensorLevels;
