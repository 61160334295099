import React, {FC} from 'react';
import Typography from './elements/typography';

type tableHeadingT = Array<string>;

type tableColumnT = {
  [key: string]: any;
};

const Table: FC<{ columns: tableColumnT; heading: tableHeadingT }> = ({
  columns,
  heading,
}) => {
  return (
    <div className="overflow-auto h-full my-table">
      <table>
        <thead>
          <tr className={'Table__tr-heading'}>
            {heading.map((label, i) => (
              <th className={'text-left'} key={i}>
                <Typography className={'text-textGrey capitalize'}>
                  {label}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {columns.map((el: { [s: string]: any }, i: React.Key) => (
            <tr className={'Table__tr-column'} key={i}>
              {heading.map((label, i) => (
                <td className={'text-left'} key={i}>
                  {el[label] || '--'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
