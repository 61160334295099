import React, {ButtonHTMLAttributes, FC, HTMLAttributes} from 'react';
import {VIconSpinner} from '../../assets/icons';
import './base.style.css';

const Button: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { text?: string; loading?: boolean }
> = ({text, loading, children, className, ...props}) => {
  return (
    <button
      className={`v-btn ${className ?? ''}`}
      {...props}
      disabled={loading}
    >
      {loading && <VIconSpinner className="btn-loading mr-2" />}
      {text || children}
    </button>
  );
};

export const MoreOptionsButton: FC<HTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => {
  return (
    <button
      className={`relative inline-block px-1 ${className ?? ''}`}
      {...props}
    >
      <div className={'bg-grey rounded-full h-1.5 w-1.5 mb-1.5'} />
      <div className={'bg-grey rounded-full h-1.5 w-1.5 mb-1.5'} />
      <div className={'bg-grey rounded-full h-1.5 w-1.5'} />
    </button>
  );
};

export default Button;
