import {useState, useEffect} from 'react';
import {GoogleMap, Marker, LoadScript} from '@react-google-maps/api';
import Card from '../../../components/elements/card';
import Typography from '../../../components/elements/typography';
import {ISensor, IWaterStation, ILocation} from 'model/waterStation';
import SensorSearchBar from 'components/sensorSearchBar';
import {demoMapStyles} from '../../../constants';
import './base.style.css';

const SensorMap = ({waterStation}: { waterStation: IWaterStation }) => {
  const [sensorLocations, setSensorLocations] = useState<ISensor[]>([]);
  const [center, setCenter] = useState({lat: 0, lng: 0});

  const handleChange = (index: number) => {
    waterStation.setActiveSensor(index);
  };

  useEffect(() => {
    setSensorLocations(waterStation.sensors);
  }, [waterStation.sensors]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      const sensorLoc: ILocation =
        waterStation.sensors[waterStation.activeSensorIndex].location;
      setCenter(createKey(sensorLoc));
    }
  }, [waterStation]);

  const createKey = (location: ILocation) => {
    return {
      lat: location.latitude,
      lng: location.longitude,
    };
  };

  return (
    <Card maxHeight={420} className="map-card small-card-last">
      <div className={'card-header'}>
        <div className={'card-header-section1'}>
          <Typography
            size={'sub'}
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            sensor map
          </Typography>

          <SensorSearchBar
            style={{zIndex: 1}}
            sensorList={waterStation.sensors}
            inputStyle={{height: 48, maxHeight: 'auto', maxWidth: 400}}
            placeholder="search sensors"
            onChange={waterStation.setActiveSensor}
          />
        </div>
      </div>
      <div>
        <LoadScript
          id="d97044c47eef0032"
          googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
          language="en"
          region="EN"
          version="weekly"
        >
          <GoogleMap
            id="d97044c47eef0032"
            mapContainerStyle={{
              height: '300px',
              width: '100%',
            }}
            zoom={3.5}
            center={center}
            options={{styles: demoMapStyles}}
          >
            {sensorLocations.map((location: ISensor, key: number) => {
              if (key === waterStation.activeSensorIndex) {
                return (
                  <Marker
                    key={key}
                    icon={{
                      path: 'M 15.272,31.458c 0.168,0.186, 0.33,0.306, 0.486,0.39c 0.002,0.002, 0.006,0.002, 0.008,0.004 c 0.108,0.056, 0.214,0.098, 0.314,0.098c 0.1,0, 0.206-0.042, 0.314-0.098c 0.002-0.002, 0.006-0.002, 0.008-0.004 c 0.156-0.084, 0.318-0.204, 0.486-0.39c0,0, 9.296-10.11, 10.23-18.87c 0.056-0.452, 0.094-0.91, 0.094-1.376C 27.212,5.020, 22.192,0, 16,0 S 4.788,5.020, 4.788,11.212c0,0.474, 0.038,0.936, 0.096,1.394C 5.842,21.362, 15.272,31.458, 15.272,31.458z M 16,4 c 3.976,0, 7.212,3.236, 7.212,7.212c0,3.976-3.236,7.212-7.212,7.212S 8.788,15.188, 8.788,11.212C 8.788,7.236, 12.024,4, 16,4z',
                      fillColor: '#0d3d5e',
                      fillOpacity: 0.9,
                      scale: 1,
                      strokeColor: '#0d3d5e',
                      strokeWeight: 1,
                    }}
                    zIndex={5}
                    onClick={() => handleChange(key)}
                    title="active"
                    position={createKey(location.location)}
                  />
                );
              }
              if (location.has_issue) {
                return (
                  <Marker
                    key={key}
                    icon={{
                      path: 'M 2.062,32l 27.812,0 c 0.702,0, 1.354-0.368, 1.716-0.972c 0.362-0.602, 0.38-1.35, 0.050-1.97l-13.876-26 C 17.418,2.408, 16.74,2, 16.002,2L 16,2 C 15.262,2, 14.586,2.406, 14.238,3.056l-13.938,26c-0.332,0.62-0.314,1.368, 0.046,1.972 C 0.708,31.63, 1.36,32, 2.062,32z M 16,24c 1.104,0, 2,0.896, 2,2c0,1.104-0.896,2-2,2s-2-0.896-2-2C 14,24.896, 14.896,24, 16,24z M 14,20.032 l0-8 c0-1.104, 0.896-2, 2-2s 2,0.896, 2,2l0,8 c0,1.104-0.896,2-2,2S 14,21.136, 14,20.032z',
                      fillColor: '#F4A335',
                      fillOpacity: 1,
                      scale: 0.7,
                      strokeColor: '#fff',
                      strokeWeight: 1,
                    }}
                    zIndex={3}
                    onClick={() => handleChange(key)}
                    position={createKey(location.location)}
                  />
                );
              }
              return (
                <Marker
                  key={key}
                  position={createKey(location.location)}
                  onClick={() => handleChange(key)}
                  icon={{
                    path: 'M 15.272,31.458c 0.168,0.186, 0.33,0.306, 0.486,0.39c 0.002,0.002, 0.006,0.002, 0.008,0.004 c 0.108,0.056, 0.214,0.098, 0.314,0.098c 0.1,0, 0.206-0.042, 0.314-0.098c 0.002-0.002, 0.006-0.002, 0.008-0.004 c 0.156-0.084, 0.318-0.204, 0.486-0.39c0,0, 9.296-10.11, 10.23-18.87c 0.056-0.452, 0.094-0.91, 0.094-1.376C 27.212,5.020, 22.192,0, 16,0 S 4.788,5.020, 4.788,11.212c0,0.474, 0.038,0.936, 0.096,1.394C 5.842,21.362, 15.272,31.458, 15.272,31.458z M 16,4 c 3.976,0, 7.212,3.236, 7.212,7.212c0,3.976-3.236,7.212-7.212,7.212S 8.788,15.188, 8.788,11.212C 8.788,7.236, 12.024,4, 16,4z',
                    fillColor: '#B2B2B2',
                    fillOpacity: 1,
                    scale: 0.7,
                    strokeWeight: 1,
                  }}
                />
              );
            })}
          </GoogleMap>
        </LoadScript>
      </div>
    </Card>
  );
};

export default SensorMap;
