import {
  VIChlorine,
  VIPh,
  VIConductivity,
  VITemperature,
  VITurbidity,
} from 'assets/icons';
import {IL, IchemicalList} from 'model/chemicalRecord';
import {Ioption} from 'model/home';

export const compareOptions: Ioption = {
  'Last 1 hour': '5T',
  'Last 24 hours': '1H',
  'Last 7 days': '1D',
  'Last 30 days': '1D',
  'Last 3 month': '7D',
  'Last 12 month': '1M',
};

export const chemicalColors = [
  '#00A8D7',
  '#D9683C',
  '#019293',
  '#74A242',
  '#C83D5C',
];

export const chemicals: IchemicalList[] = [
  'chlorine',
  'ph',
  'turbidity',
  'conductivity',
  'water temperature',
];

export const Legends: IL = {
  'chlorine': {
    text: 'Chlorine (0.2 - 2 mg/l)',
    Icon: VIChlorine,
    theme: 'svg-chlorine-active',
    id: 'chlorine',
  },
  'water temperature': {
    text: `Temperature (${50}° - ${80}° F)`,
    Icon: VITemperature,
    theme: 'svg-temperature-active',
    id: 'temperature',
  },
  'turbidity': {
    text: 'Turbidity (0 - 50 NTU)',
    Icon: VITurbidity,
    theme: 'svg-turbidity-active',
    id: 'turbidity',
  },
  'ph': {
    text: 'pH (6.5 - 8.5)',
    Icon: VIPh,
    theme: 'svg-ph-active',
    id: 'ph',
  },
  'conductivity': {
    text: 'Conductivity (200 - 800 µS/cm)',
    Icon: VIConductivity,
    theme: 'svg-conductivity-active',
    id: 'conductivity',
  },
};

export const demoMapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];
