import {useState, useEffect, ChangeEvent} from 'react';
import Input from 'components/elements/inputs';
import {VIconSearch} from '../assets/icons';
import {ISensor} from 'model/waterStation';

interface Iprops {
  placeholder?: string;
  inputStyle?: {
    [key: string]: string | number;
  };
  selectedSensor?: string;
  sensorList?: ISensor[];
  onChange?: (val: number) => void;
  style?: Object;
}

const SensorSearchBar = ({
  placeholder = '',
  inputStyle = {},
  sensorList = [],
  onChange,
  selectedSensor,
  style = {},
}: Iprops) => {
  const [searchBarValue, setSearchBarValue] = useState<string>(
      selectedSensor || '',
  );
  const [isOpen, toggle] = useState<boolean>(false);
  const [searchQueryList, setSearchQueryList] = useState<ISensor[]>([]);

  const handleSelect = (newValue: ISensor, key: number) => {
    setSearchBarValue(newValue.location.formatted_address);

    if (onChange) {
      const index = sensorList.findIndex(
          (sensor) => sensor.device_id === newValue.device_id,
      );
      onChange(index);
    }
  };
  const handleToggle = (state?: boolean) => {
    if (state) {
      toggle(!!state);
    } else {
      toggle(!isOpen);
    }
  };

  useEffect(() => {
    setSearchBarValue(selectedSensor || '');
    setSearchQueryList(sensorList);
  }, [selectedSensor, sensorList]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    const inputContainsAValue = !!value.trim();
    const sensorQueryList: ISensor[] = [];

    sensorList.forEach((sensor) => {
      const formattedValue = value.toLowerCase();
      const checkaddress = `${sensor.location.formatted_address}`
          .toLowerCase()
          .includes(formattedValue);
      const checkCountry = `${sensor.location.country}`
          .toLowerCase()
          .includes(formattedValue);
      const checkWaterUtilityName = `${sensor.water_utility.name}`
          .toLowerCase()
          .includes(formattedValue);
      const checkZipCode = `${sensor.location.zip_code}`
          .toLowerCase()
          .includes(formattedValue);
      const checkWaterSystemId = `${sensor.water_utility.water_system_id}`
          .toLowerCase()
          .includes(formattedValue);
      if (
        checkaddress ||
        checkCountry ||
        checkWaterUtilityName ||
        checkZipCode ||
        checkWaterSystemId
      ) {
        sensorQueryList.push(sensor);
      }
    });

    setSearchBarValue(value);
    setSearchQueryList(sensorQueryList);
    if (inputContainsAValue) {
      handleToggle(true);
    } else {
      handleToggle();
    }
  };

  return (
    <div className="relative inline-block text-left" style={style}>
      <div>
        <Input
          style={inputStyle}
          value={searchBarValue}
          onChange={handleChange}
          placeholder={placeholder}
          suffix={<VIconSearch style={{fontSize: 24}} />}
        />
      </div>
      {isOpen && (
        <div
          className="w-full origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
          onClick={() => handleToggle()}
        >
          <div
            className="py-2 overflow-y-scroll"
            role="none"
            style={{maxHeight: '20vh'}}
          >
            {searchQueryList.map((sensor, key) => (
              <span
                key={key}
                onClick={() => handleSelect(sensor, key)}
                className="drop-down-item cursor-pointer text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                {sensor.location.formatted_address}
              </span>
            ))}
            {searchQueryList.length === 0 && (
              <span
                onClick={() => handleToggle()}
                className="text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                No data to display
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SensorSearchBar;
