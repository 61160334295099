import React from 'react';
import {ISensor, IWaterStation} from '../model/waterStation';

export const sensor: ISensor = {
  id: 0,
  device_id: '',
  water_utility: {
    id: 0,
    location: {
      street_address_1: '',
      street_address_2: null,
      city: '',
      state: '',
      zip_code: '',
      country: '',
      longitude: 0,
      latitude: 0,
      formatted_address: '',
    },
    name: '',
    url: null,
    water_system_id: '',
    population: 0,
    num_meters: 0,
    primary_water_source_type: '',
    water_system_type: '',
    is_pws_activity_active: false,
    principal_county_served: null,
    principal_city_served: null,
    system_status: '',
    activity_date: '',
  },
  location: {
    street_address_1: '',
    street_address_2: null,
    city: '',
    state: '',
    zip_code: '',
    country: '',
    longitude: 0,
    latitude: 0,
    formatted_address: '',
  },
  has_issue: false,
};

const sensors: ISensor[] = [];

export const waterStation: IWaterStation = {
  sensors,
  activeSensorIndex: 0,
  setSensors: () => {},
  setActiveSensor: () => {},
};

export const WaterStationContext = React.createContext(waterStation);
