import request from './request';
import {IChemicalRecord} from '../model/chemicalRecord';
import {Ireport} from '../model/report';

export const getReport = ({id, chemical}: IChemicalRecord) =>
  request.request<Ireport>({
    url: '/reports/weekly-reports/',
    method: 'GET',
    params: {
      sensor_id: id,
      chemical,
    },
  });
