import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Link, useHistory, RouteComponentProps} from 'react-router-dom';
import Input from '../../components/elements/inputs';
import Button from '../../components/elements/button';
import Typography from 'components/elements/typography';
import {login} from 'api/login';
import {Ilogin, LoginData, IResponse} from 'model/login';
import {IUpdateProfile} from 'model/user';
import {storage} from '../../util';

const defaultState: Ilogin = {
  error: '',
  email: '',
  password: '',
  loading: false,
  emailError: false,
  passwordError: false,
};

const resetError = {
  error: '',
  loading: false,
  emailError: false,
  passwordError: false,
};

interface Iprops extends IUpdateProfile {}

const LoginForm = ({setProfile}: Iprops) => {
  const history: RouteComponentProps['history'] = useHistory();
  const [state, setState] = useState(defaultState);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      email: state.email,
      password: state.password,
    };

    if (!data.email) {
      setState({
        ...state,
        error: 'Email field cannot be empty',
        emailError: true,
      });
    } else if (!data.password) {
      setState({
        ...state,
        error: 'Password field cannot be empty',
        passwordError: true,
      });
    } else {
      onlogin(data);
    }
  };

  const onlogin = async (data: LoginData) => {
    setState({...state, loading: true});
    try {
      const res: { data: IResponse } = await login(data);

      /** add token to storage */
      storage.setItem('user', JSON.stringify(res.data.profile));
      storage.setItem('accessToken', JSON.stringify(res.data.token));

      /** add response to state */
      setProfile(res.data.profile);

      setState({...state, loading: false});

      /** navigate to dashboard */
      history.push('/home');
    } catch (e: any) {
      setState({
        ...state,
        error: e?.response?.data?.detail,
      });
      console.log({e});
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState({
      ...state,
      ...resetError,
      [name]: value,
    });
  };

  const onRememberMe = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
        'rememberVarunaUser',
        JSON.stringify(e.target.checked),
    );
  };
  return (
    <>
      <form className={'grid gap-5'} onSubmit={handleSubmit}>
        <div className="error">{state.error}</div>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          onChange={handleChange}
          value={state.email}
          error={state.emailError}
        />
        <Input
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          value={state.password}
          error={state.passwordError}
        />
        <div className="flex justify-between items-start mb-3">
          <Input.CheckBox label="Remember Me" onChange={onRememberMe} />
          <Button type="submit" text="LOGIN" loading={state.loading} />
        </div>
      </form>
      <div className="flex justify-end">
        <Link to="/">
          <Typography size="sm" className="text-textGrey font-bold">
            Forgot your password?
          </Typography>
        </Link>
      </div>
    </>
  );
};

export default LoginForm;
