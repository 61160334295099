import React, {useState, ReactNode} from 'react';
import {ISensor, IWaterStation} from 'model/waterStation';
import {WaterStationContext, waterStation} from './waterStationContext';

const WaterStationProvider = ({children}: { children: ReactNode }) => {
  const [state, setState] = useState<IWaterStation>(waterStation);

  const saveSensors = (sensors: ISensor[]) => {
    setState({...state, sensors});
  };

  const setActiveSensor = (index: number) => {
    setState({...state, activeSensorIndex: index});
  };
  return (
    <WaterStationContext.Provider
      value={{...state, setSensors: saveSensors, setActiveSensor}}
    >
      {children}
    </WaterStationContext.Provider>
  );
};

export default WaterStationProvider;
