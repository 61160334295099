import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import {BrowserRouter} from 'react-router-dom';
import UserProvider from 'context/userProvider';
import WaterStationProvider from 'context/waterStationProvider';
import './index.css';

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <WaterStationProvider>
          <UserProvider>
            <Routes />
          </UserProvider>
        </WaterStationProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
