import {ResponsiveLine, Layer} from '@nivo/line';
import ToolTip from './tooltip';
import SafeBand from './compareChartSafeBand';
import {theme} from './utils/constant';
import {getAxisFormat, calculateBandLevels} from './utils/compare';
import {IcompareData, Iband} from 'model/compare';
interface IProps extends IcompareData {
  interval: string;
}

export const CompartChart = ({data, interval}: IProps) => {
  const bandLevel: Iband = calculateBandLevels(data);
  const bandLevelMAx: number = bandLevel.max + 20;
  const bandLevelMin: number = bandLevel.min - 10;
  const Layers: Layer[] | undefined = [
    SafeBand,
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
  ];
  return (
    <ResponsiveLine
      margin={{bottom: 50, right: 30, left: 45, top: 7}}
      xFormat="time:%Y-%m-%d %H:%M"
      colors={(r) => r.color}
      axisTop={{tickValues: []}}
      axisRight={{tickValues: []}}
      axisBottom={getAxisFormat(interval)}
      axisLeft={{tickValues: []}}
      enableGridY={false}
      areaOpacity={0.3}
      theme={theme}
      enableSlices={false}
      data={data}
      yScale={{
        type: 'linear',
        stacked: false,
        reverse: true,
        min: bandLevelMAx,
        max: bandLevelMin,
      }}
      curve="monotoneX"
      xScale={{
        type: 'time',
        format: '%Y-%m-%d %H:%M',
        precision: 'minute',
      }}
      layers={Layers}
      pointSize={10}
      pointColor="white"
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      useMesh={true}
      tooltip={(point) => <ToolTip point={point} data={data} />}
    />
  );
};

export default CompartChart;
