import React, {ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {storage} from '../util';

const ProtectedRoute = ({children}: { children: ReactNode }) => {
  const user = storage.getItem('user');
  if (user) {
    return <>{children}</>;
  }
  return <Redirect to="/login" />;
};

export default ProtectedRoute;
