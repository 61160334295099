import {useState, useEffect, useCallback, useMemo} from 'react';
import Typography from '../../../components/elements/typography';
import Card from '../../../components/elements/card';
import MyResponsiveLine from 'components/compareChart';
import {IkeyValues} from 'model/home';
import Dropdown from 'components/dropdown';
import './base.style.css';
import {chemicalColors, compareOptions} from '../../../constants';
import {IWaterStation} from 'model/waterStation';
import {
  IchartData,
  Ichart,
  IchemicalList,
  IChemicalMeasurements,
} from 'model/chemicalRecord';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {chemicals, Legends} from '../../../constants';
import {AxiosResponse} from 'axios';
import {IcompareChart} from 'model/compare';
import {Ivalue} from 'model/home';
import {convertFromCelciusToFarenheight} from '../../../util';
import {fillToNow} from './utils/compare';
import moment from 'moment';
import {Ilegend} from 'model/home';

const IconSize = {
  width: 30,
  height: 30,
};

const Compare = ({waterStation}: { waterStation: IWaterStation }) => {
  const [selected, setSelected] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [graphData, setGraphData] = useState<Ichart>({} as Ichart);
  const selectedInterval: IkeyValues = useMemo(
      () => chemicalInterval[selected] as IkeyValues,
      [selected, chemicalInterval],
  );
  const chartData: IcompareChart[] = useMemo(() => {
    const chemicalList: string[] = graphData ? Object.keys(graphData) : [];
    if (chemicalList.length) {
      const datasets: IcompareChart[] = chemicalList?.map(
          (chemical: string) => {
            const chemicalIndex = chemicals.indexOf(chemical as IchemicalList);
            const unit: string =
            graphData[chemical as IchemicalList][0]?.unit ?? '';
            const formattedUnit: string = unit === 'ppm' ? 'mg/l' : unit;
            const d: IcompareChart = {
              id: 'chlorine',
              color: '',
              data: [],
              unit: '',
            };
            d['id'] = chemicals[chemicalIndex];
            d['color'] = chemicalColors[chemicalIndex];
            d['unit'] = formattedUnit;
            d['data'] = graphData[chemical as IchemicalList].map(
                (data: IchartData) => ({
                  x: moment(data.time).format('YYYY-MM-DD HH:mm'),
                  y:
                chemicals[chemicalIndex] === 'water temperature' ?
                  Number(convertFromCelciusToFarenheight(data.value)) :
                  Math.round(data.value * 1000) / 1000,
                }),
            );
            return d;
          },
      );
      return datasets;
    } else {
      return [];
    }
  }, [graphData]);

  const fetchRecords = useCallback(async () => {
    const selectedSensor = waterStation.sensors[waterStation.activeSensorIndex];
    const interval: Ivalue = compareOptions[selectedInterval];

    const requests = chemicals.map((chemical) =>
      getChemicalRecordByInterval({
        id: selectedSensor.id,
        chemical,
        interval: interval,
      }),
    );

    const resp: AxiosResponse<IChemicalMeasurements>[] = await Promise.all(
        requests,
    );

    const reducer = (
        prev: any,
        record: AxiosResponse<IChemicalMeasurements>,
    ) => {
      const chemical: IchemicalList = `${
        record?.config?.url?.split('/')[4] ?? 'chlorine'
      }` as IchemicalList;
      if (record.data.results.records.length) {
        return {
          ...prev,
          [chemical]: fillToNow(
              record.data.results.records,
              interval,
              selectedInterval,
          ),
        };
      } else {
        return prev;
      }
    };

    const chartData = resp.reduce(reducer, {});
    setGraphData(chartData);
    // eslint-disable-next-line
  }, [waterStation, selected]);

  const onChangeInterval = (index: number) => {
    setSelected(index);
  };

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
    }
  }, [fetchRecords, waterStation.sensors.length]);

  return (
    <Card
      className="hidden md:grid v-card lg:col-start-1 lg:col-end-3 large-card"
      style={{maxHeight: 'calc(100vh - 120px)'}}
    >
      <div className="card-header">
        <div className="card-header-section1 black-drop-down">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            compare
          </Typography>

          <Dropdown
            style={{maxWidth: 267, zIndex: 100000}}
            list={chemicalInterval}
            value={chemicalInterval[selected]}
            onChange={onChangeInterval}
          />
        </div>
      </div>
      <div className="grid grid-cols-auto1fr gap-4">
        <div className="compare__legend flex flex-col gap-5">
          {Object.keys(graphData).map((id: string, key: number) => {
            const chemical: Ilegend = Legends[id as IchemicalList];
            const {text, Icon, theme} = chemical;
            return (
              <div key={key} className="flex items-center cursor-pointer">
                <Icon
                  style={{marginRight: 12, ...IconSize}}
                  className={theme}
                />
                <Typography
                  className="font-bold text-textBlack
                whitespace-nowrap"
                >
                  {text}
                </Typography>
              </div>
            );
          })}
        </div>
        <div
          className="compare-graph compare-graph-minimal"
          style={{zIndex: 100000}}
        >
          {Object.keys(graphData).length ? (
            <MyResponsiveLine data={chartData} interval={selectedInterval} />
          ) : (
            ''
          )}
        </div>
      </div>
    </Card>
  );
};

export default Compare;
