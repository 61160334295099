import React, {ReactNode} from 'react';
import SideNav, {BottomNav} from 'shared/sideNav';
import Header from 'shared/header';
import './base.style.css';
import {IUserDetails} from 'model/user';
import {IWaterStation} from 'model/waterStation';

interface Iprops {
  user: IUserDetails;
  children: ReactNode;
  waterStation: IWaterStation;
}

const DashboardLayout = ({children, user, waterStation}: Iprops) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-auto1fr ">
      <div className="hidden md:block v-dashboard-left">
        <SideNav />
      </div>
      <div>
        <div className="v-dashboard-top">
          <Header user={user} waterStation={waterStation} />
        </div>
        <div className="v-dashboard-content">
          <div className="container m-auto ">{children}</div>
        </div>
      </div>
      <div className="v-dashboard-bottom flex md:hidden">
        <BottomNav
          firstName=""
          lastName=""
          image="https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
        />
      </div>
    </div>
  );
};

export default DashboardLayout;
