import {useState, useCallback, useEffect} from 'react';
import Typography from '../../../components/elements/typography';
import Card from '../../../components/elements/card';
import './base.style.css';
import Table from '../../../components/table';
import {
  representTableData,
  convertFromCelciusToFarenheight,
} from '../../../util';
import {IWaterStation} from 'model/waterStation';
import Dropdown from 'components/dropdown';
import {chemicals} from '../../../constants';
import {
  VIChlorine,
  VIConductivity,
  VITemperature,
  VIPh,
  VITurbidity,
} from '../../../assets/icons';
import {compareOptions} from '../../../constants';
import {IkeyValues} from 'model/home';
import {IchemicalList, IChemicalMeasurements} from 'model/chemicalRecord';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {AxiosResponse} from 'axios';

interface Itrend {
  value: string;
  rate: string;
}
interface IOverviewTrend {
  parameter: string;
  value: string;
  trend: Itrend;
  unit: string;
}
const unitRate = (type: string) => <div className={`rate-is-${type}`} />;
const IconSize = {
  width: 30,
  height: 30,
};
const iconFunc = (name: string) => {
  switch (name) {
    case 'turbidity':
      return <VITurbidity className="svg-turbidity-active" style={IconSize} />;
    case 'chlorine':
      return <VIChlorine className="svg-chlorine-active" style={IconSize} />;
    case 'ph':
      return <VIPh className="svg-ph-active" style={IconSize} />;
    case 'water temperature':
      return (
        <VITemperature className="svg-temperature-active" style={IconSize} />
      );
    case 'conductivity':
      return (
        <VIConductivity className="svg-conductivity-active" style={IconSize} />
      );
  }
};

const getOverview = (overviewData: IOverviewTrend[]) =>
  representTableData(overviewData, (res) => ({
    parameter: (
      <div className="flex items-center">
        {iconFunc(res.parameter)}
        <Typography className="font-bold text-textGrey ml-2 capitalize ">
          {res.parameter}
        </Typography>
      </div>
    ),
    value: <Typography className="font-bold">{res.value}</Typography>,
    unit: res.unit,
    trend: (
      <div className="flex items-center">
        <span className="mr-2">{unitRate(res.trend.rate)}</span>
        {res.trend.value}
      </div>
    ),
  }));

const Overview = ({waterStation}: { waterStation: IWaterStation }) => {
  const [selected, setSelected] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [chemicalOverviewData, setData] = useState<IOverviewTrend[]>([]);
  const onChangeInterval = (index: number) => {
    setSelected(index);
  };
  const fetchRecords = useCallback(async () => {
    if (waterStation.sensors.length > 0) {
      const selectedSensor =
        waterStation.sensors[waterStation.activeSensorIndex];
      const selectedInterval: IkeyValues = chemicalInterval[
          selected
      ] as IkeyValues;

      const requests = chemicals.map((chemical) =>
        getChemicalRecordByInterval({
          id: selectedSensor.id,
          chemical,
          interval: compareOptions[selectedInterval],
        }),
      );
      const resp = await Promise.all(requests);

      const chemicalsWithData: number[] = [];
      const chemicalsWithDataRecord: AxiosResponse<IChemicalMeasurements>[] =
        [];

      resp.forEach((data, key) => {
        chemicalsWithData.push(key);
        chemicalsWithDataRecord.push(data);
      });

      const result: IOverviewTrend[] = chemicalsWithDataRecord.map(
          ({config, data}, key) => {
            const chemical: IchemicalList = `${
              config?.url?.split('/')[4] ?? 'chlorine'
            }` as IchemicalList;
            const isTemperature: boolean = chemical === 'water temperature';
            const currentVal: number = data.results.records[0]?.value ?? '';
            const unit: string = data.results.records[0]?.unit ?? '';
            const formattedUnit: string = unit === 'ppm' ? 'mg/l' : unit;
            const isUnitCelcius: boolean = formattedUnit === '°C';
            const convertedCurrentVal =
            isTemperature && isUnitCelcius ?
              convertFromCelciusToFarenheight(currentVal) :
              currentVal ?
              Number(currentVal).toFixed(2) :
              '';
            const convertedUnit = formattedUnit === '°C' ? '°F' : formattedUnit;
            const d: IOverviewTrend = {
              parameter: chemical,
              value: `${convertedCurrentVal}`,
              trend: {value: '', rate: ''},
              unit: convertedUnit,
            };
            const previousVal = data.results.records[1]?.value;
            if (currentVal && previousVal) {
              const trend = currentVal - previousVal;
              const convertedTrend =
              isTemperature && isUnitCelcius ?
                Number(convertFromCelciusToFarenheight(currentVal)) -
                  Number(convertFromCelciusToFarenheight(previousVal)) :
                trend;

              d['trend'] = {
                value: `${Math.abs(convertedTrend).toFixed(2)}`,
                rate: `${currentVal > previousVal ? 'up' : 'down'}`,
              };
            }
            return d;
          },
      );
      setData(result);
    }
    // eslint-disable-next-line
  }, [waterStation, selected]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
    }
  }, [fetchRecords, waterStation.sensors.length]);
  return (
    <Card maxHeight={420} className="small-card-first">
      <div className="card-header">
        <div
          className=" card-header-section1 black-drop-down"
          style={{maxWidth: 400}}
        >
          <Typography
            size={'sub'}
            className={'text-normal-blue uppercase font-bold'}
          >
            overview
          </Typography>
          <Dropdown
            style={{zIndex: 100000}}
            list={chemicalInterval}
            value={chemicalInterval[selected]}
            onChange={onChangeInterval}
          />
        </div>
      </div>
      <div className="v-card-table-heading  my-table v-card-table no-table-border-bottom">
        <Table
          heading={['parameter', 'value', 'trend', 'unit']}
          columns={getOverview(chemicalOverviewData)}
        />
      </div>
    </Card>
  );
};

export default Overview;
