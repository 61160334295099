import {IMeasurementRecord} from 'model/chemicalRecord';
import {Ivalue, ICountOption, IkeyValues} from 'model/home';
import moment from 'moment';

export const compareOptions: ICountOption = {
  'Last 1 hour': 10,
  'Last 24 hours': 24,
  'Last 7 days': 7,
  'Last 30 days': 30,
  'Last 3 month': 6,
  'Last 12 month': 12,
};

export const fillToNow = (
    records: IMeasurementRecord[],
    interval: Ivalue,
    period: IkeyValues,
) => {
  const now: number = Math.round(Date.now() / 1000);
  const intervalValue: number = Number(interval[0]);
  const intervalUnit: any = `${interval[1]}`.toLowerCase();
  const validatedIntervalUnit =
    intervalUnit === 't' ? 'd' : intervalUnit === 'm' ? 'M' : intervalUnit;
  const intervalInSec: number = moment
      .duration(intervalValue, validatedIntervalUnit)
      .asSeconds();
  const formattedIntervalInSec: number =
    String(intervalInSec) === 'NaN' ?
      0 :
      String(intervalInSec) === 'Infinity' ?
      1000000 :
      intervalInSec;
  const formatStr: string = 'YYYY-MM-DDTHH:mm:ss';
  const count: number = compareOptions[period];

  if (records.length) {
    const firstRecord = moment(
        records[0].time.substring(0, 19) + '-00:00',
    ).unix();
    const newRecordCount = Math.round(
        (now - firstRecord) / formattedIntervalInSec,
    );

    return new Array(newRecordCount)
        .fill(0)
        .map((x, i) => ({
          value: 0,
          time: moment
              .unix(firstRecord + (newRecordCount - i) * intervalValue)
              .format(formatStr),
        }))
        .concat(records)
        .slice(0, count);
  } else {
    return new Array(count).fill(0).map((x, i) => ({
      value: 0,
      time: moment.unix(now - i * intervalInSec).format(formatStr),
    }));
  }
};
