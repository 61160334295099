import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from 'pages/authentication';
import Notfound from 'pages/notfound';
import Dashboard from 'pages/dashboard';
import {UserContext} from 'context/userContext';
import {WaterStationContext} from 'context/waterStationContext';
import {IWaterStation} from 'model/waterStation';
import 'tailwindcss/tailwind.css';

const Routes = () => (
  <WaterStationContext.Consumer>
    {(waterStation: IWaterStation) => (
      <UserContext.Consumer>
        {(props) => {
          return (
            <Switch>
              <Route exact path="/login">
                <Login
                  setProfile={props.setProfile}
                  setSensors={waterStation.setSensors}
                />
              </Route>
              <Route path="/">
                <Dashboard user={props} waterStation={waterStation} />
              </Route>
              <Route path="*">
                <Notfound />
              </Route>
            </Switch>
          );
        }}
      </UserContext.Consumer>
    )}
  </WaterStationContext.Consumer>
);

export default Routes;
