import request from './request';
import {LoginData} from 'model/login';
import {IResponse} from 'model/login';

export const login = (data: LoginData) =>
  request.request<IResponse>({
    url: '/auth/login/',
    data,
    method: 'POST',
  });
